import React, { Suspense, Fragment, lazy } from "react";
import { Switch, Redirect, Route } from "react-router-dom";

import Loader from "./components/Loader/Loader";
import AdminLayout from "./layouts/AdminLayout";

import GuestGuard from "./components/Auth/GuestGuard";
import AuthGuard from "./components/Auth/AuthGuard";

import configs from "./config";

export const renderRoutes = (routes = []) => (
   <Suspense fallback={<Loader />}>
      <Switch>
         {routes.map((route, i) => {
            const Guard = route.guard || Fragment;
            const Layout = route.layout || Fragment;
            const Component = route.component;

            return (
               <Route
                  key={i}
                  path={route.path}
                  exact={route.exact}
                  render={(props) => (
                     <Guard>
                        <Layout>
                           {route.routes ? renderRoutes(route.routes) : <Component {...props} />}
                        </Layout>
                     </Guard>
                  )}
               />
            );
         })}
      </Switch>
   </Suspense>
);

const routes = [
   {
      exact: true,
      guard: GuestGuard,
      path: "/auth/signin",
      component: lazy(() => import("./views/Auth/SignIn")),
   },
   {
      exact: true,
      guard: AuthGuard,
      path: "/auth/signout",
      component: lazy(() => import("./views/Auth/SignOut")),
   },
   {
      exact: true,
      path: "/404",
      component: lazy(() => import("./views/Errors/NotFound404")),
   },
   {
      path: "*",
      layout: AdminLayout,
      guard: AuthGuard,
      routes: [
         {
            exact: true,
            path: "/site/dashboard",
            component: lazy(() => import("./views/Site/Dashboard")),
         },
         {
            exact: true,
            path: "/site/visitors",
            component: lazy(() => import("./views/Site/Visitors")),
         },
         {
            exact: true,
            path: "/email/outemaillogs",
            component: lazy(() => import("./views/Emails/SentEmailLogs")),
         },
         {
            exact: true,
            path: "/email/inemaillogs",
            component: lazy(() => import("./views/Emails/ReceivedEmailLogs")),
         },
         {
            exact: true,
            path: "/my/drive",
            component: lazy(() => import("./views/MyCloud/Drive")),
         },
         {
            exact: true,
            path: "/account/billing",
            component: lazy(() => import("./views/Account/Billing")),
         },
         {
            exact: true,
            path: "/account/billing/payment",
            component: lazy(() => import("./views/Account/Billing/Payment")),
         },
         // {
         //    exact: true,
         //    path: "/sample-page",
         //    component: lazy(() => import("./views/Extra/SamplePage")),
         // },
         {
            path: "*",
            exact: true,
            component: () => <Redirect to={configs.BASE_URL} />,
         },
      ],
   },
];

export default routes;
