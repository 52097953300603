import React, { useContext } from "react";
import { ListGroup, Dropdown } from "react-bootstrap";

import useWindowSize from "../../../../hooks/useWindowSize";
import { ConfigContext } from "../../../../contexts/ConfigContext";
import { SessionContext } from "../../../../contexts/SessionContext";
import JWTContext from "../../../../contexts/JWTContext";
import { SessionStateActionType } from "./../../../../store/sessionActions";

const NavLeft = () => {
   const windowSize = useWindowSize();

   const configContext = useContext(ConfigContext);
   const session = useContext(SessionContext);
   const jwt = useContext(JWTContext);

   const { rtlLayout } = configContext.state;
   let dropdownRightAlign = false;
   if (rtlLayout) {
      dropdownRightAlign = true;
   }

   let navItemClass = ["nav-item"];
   // if (windowSize.width <= 575) {
   //    navItemClass = [...navItemClass, "d-none"];
   // }

   return (
      <React.Fragment>
         <ListGroup as="ul" bsPrefix=" " className="navbar-nav mr-auto">
            <ListGroup.Item as="li" bsPrefix=" " className={navItemClass.join(" ")}>
               <Dropdown alignRight={dropdownRightAlign}>
                  <Dropdown.Toggle variant={"link"} id="dropdown-basic">
                     {session.state.domain}
                  </Dropdown.Toggle>
                  <ul>
                     <Dropdown.Menu>
                        {jwt.user.domains.map((domain) => {
                           return (
                              <li key={domain}>
                                 <Dropdown.Item
                                    className="dropdown-item"
                                    onClick={() => {
                                       session.dispatch({
                                          type: SessionStateActionType.SetDomain,
                                          domain: domain,
                                       });
                                    }}
                                 >
                                    {domain}
                                 </Dropdown.Item>
                              </li>
                           );
                        })}
                     </Dropdown.Menu>
                  </ul>
               </Dropdown>
            </ListGroup.Item>
         </ListGroup>
      </React.Fragment>
   );
};

export default NavLeft;
