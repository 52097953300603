import React from "react";
import { BrowserRouter as Router } from "react-router-dom";

import { JWTProvider } from "./contexts/JWTContext";

import routes, { renderRoutes } from "./routes";
import configs from "./config";
import { SessionProvider } from "./contexts/SessionContext";
import ScrollToTop from "./views/common/ScrollToTop";

const App = () => {
   return (
      <React.Fragment>
         <SessionProvider>
            <Router basename={configs.BASENAME}>
               <ScrollToTop />
               <JWTProvider>{renderRoutes(routes)}</JWTProvider>
            </Router>
         </SessionProvider>
      </React.Fragment>
   );
};

export default App;
