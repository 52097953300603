import React from "react";
import { Redirect } from "react-router-dom";
import configs from "../../config";

import useAuth from "../../hooks/useAuth";

const GuestGuard = ({ children }) => {
   const { isLoggedIn } = useAuth();

   if (isLoggedIn) {
      return <Redirect to={configs.BASE_URL} />;
   }

   return <React.Fragment>{children}</React.Fragment>;
};

export default GuestGuard;
