export interface SessionState {
   domain?: string;
}

export enum SessionStateActionType {
   SetDomain,
}

interface SetDomain {
   type: SessionStateActionType.SetDomain;
   domain: string;
}

export type SessionStateActions = SetDomain;
