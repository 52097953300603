let configDir = "";
if (process.env.REACT_APP_ENVIRONMENT === "production") {
   configDir = "./production";
} else if (process.env.REACT_APP_ENVIRONMENT === "uat") {
   configDir = "./uat";
} else {
   configDir = "./development";
}
const configs = require(`${configDir}`);
export default configs.configs;
