import { AccountActionType } from "./actions";

export interface UserInterface {
   name: string;
   email: string;
   domains: string[];
}

export interface AccountState {
   isLoggedIn?: boolean;
   isInitialised?: boolean;
   user?: UserInterface | null;
}

interface InitAccount {
   type: AccountActionType.ACCOUNT_INITIALISE;
   payload: {
      isLoggedIn: boolean;
      user: UserInterface | null;
   };
}

interface LoginAccount {
   type: AccountActionType.LOGIN;
   payload: {
      user: UserInterface;
   };
}

interface LogoutAccount {
   type: AccountActionType.LOGOUT;
}

type AccountActions = InitAccount | LoginAccount | LogoutAccount;

const accountReducer: React.Reducer<AccountState, AccountActions> = (
   state,
   action
): AccountState => {
   switch (action.type) {
      case AccountActionType.ACCOUNT_INITIALISE: {
         const { isLoggedIn, user } = action.payload;
         return {
            ...state,
            isLoggedIn,
            isInitialised: true,
            user,
         };
      }
      case AccountActionType.LOGIN: {
         const { user } = action.payload;
         return {
            ...state,
            isLoggedIn: true,
            user,
         };
      }
      case AccountActionType.LOGOUT: {
         return {
            ...state,
            isLoggedIn: false,
            user: null,
         };
      }
      default: {
         return { ...state };
      }
   }
};

export default accountReducer;
