import React, { createContext, useReducer } from "react";
import { SessionState, SessionStateActionType, SessionStateActions } from "../store/sessionActions";

const SessionContext = createContext<{
   state: SessionState;
   dispatch: React.Dispatch<SessionStateActions>;
}>({ state: { domain: "" }, dispatch: () => undefined });
const { Provider } = SessionContext;

const SessionProvider: React.FC = ({ children }) => {
   const [state, dispatch] = useReducer(
      (state: SessionState, action: SessionStateActions): SessionState => {
         switch (action.type) {
            case SessionStateActionType.SetDomain:
               return {
                  ...state,
                  domain: action.domain,
               };
            default:
               throw new Error();
         }
      },
      {}
   );

   return <Provider value={{ state, dispatch }}>{children}</Provider>;
};

export { SessionContext, SessionProvider };
