import * as Icon from "react-feather";

const menuItems = {
   items: [
      {
         id: "website",
         title: "Website",
         type: "group",
         children: [
            {
               id: "dashboard",
               title: "Dashboard",
               type: "item",
               url: "/site/dashboard",
               classes: "nav-item",
               icon: Icon.BarChart2,
            },
            {
               id: "visitors",
               title: "Visitors",
               type: "item",
               url: "/site/visitors",
               classes: "nav-item",
               icon: Icon.Users,
            },
            // {
            //    id: "sample-page",
            //    title: "Sample Page",
            //    type: "item",
            //    url: "/sample-page",
            //    classes: "nav-item",
            //    icon: Icon.Copy,
            // },
         ],
      },
      {
         id: "emails",
         title: "Email",
         type: "group",
         children: [
            {
               id: "sent-mail",
               title: "Sent Email Logs",
               type: "item",
               url: "/email/outemaillogs",
               classes: "nav-item",
               icon: Icon.Send,
            },
            {
               id: "received-email",
               title: "Received Email Logs",
               type: "item",
               url: "/email/inemaillogs",
               classes: "nav-item",
               icon: Icon.Mail,
            },
         ],
      },
      {
         id: "cloud",
         title: "My Cloud",
         type: "group",
         children: [
            {
               id: "my-cloud-drive",
               title: "My Cloud Drive",
               type: "item",
               url: "/my/drive",
               classes: "nav-item",
               icon: Icon.HardDrive,
            },
         ],
      },
      {
         id: "account",
         title: "Account",
         type: "group",
         children: [
            {
               id: "billing",
               title: "Billing",
               type: "item",
               url: "/account/billing",
               classes: "nav-item",
               icon: Icon.DollarSign,
            },
            {
               id: "logout",
               title: "Logout",
               type: "item",
               url: "/auth/signout",
               classes: "nav-item",
               icon: Icon.LogOut,
            },
         ],
      },
   ],
};

export default menuItems;
