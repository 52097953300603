import axios, { AxiosRequestConfig } from "axios";
import configs from "../config";

export type ErrorResponse = {
   message: string;
};

const axiosService = axios.create({
   baseURL: configs.API_BASE_URL,
});

axiosService.interceptors.response.use(
   (response) => response,
   (error) =>
      Promise.reject(
         (error.response && error.response.data) || (error && error) || "Unspecified Error"
      )
);

const setAuthHeader = (authHeader: string) => {
   axiosService.defaults.headers.common.Authorization = authHeader;
};

const deleteAuthHeader = () => {
   delete axiosService.defaults.headers.common.Authorization;
};

const post = async (resourceUrl: string, data?: any) => {
   return await axiosService.post(resourceUrl, data);
};

const postByConfig = async (resourceUrl: string, data?: any, config?: AxiosRequestConfig) => {
   return await axiosService.post(resourceUrl, data, config);
};

const get = async (resourceUrl: string) => {
   return await axiosService.get(resourceUrl);
};

const deleteAction = async (resourceUrl: string) => {
   return await axiosService.delete(resourceUrl);
};

const apiServices = {
   setAuthHeader,
   deleteAuthHeader,
   get,
   post,
   postByConfig,
   deleteAction,
};

export default apiServices;
