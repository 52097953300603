import React, { createContext, useEffect, useReducer, useContext } from "react";
import jwtDecode from "jwt-decode";

import { AccountActionType } from "../store/actions";
import { SessionStateActionType } from "../store/sessionActions";
import apiServices from "../services/apiServices";
import accountReducer, { UserInterface, AccountState } from "../store/accountReducer";
import Loader from "../components/Loader/Loader";
import { SessionContext } from "./SessionContext";

const initialState: AccountState = {
   isLoggedIn: false,
   isInitialised: false,
   user: null,
};

const verifyToken = (serviceToken: string | null | undefined): boolean => {
   if (!serviceToken) {
      return false;
   }

   const decoded: any = jwtDecode(serviceToken);
   return decoded.exp > Date.now() / 1000;
};

const setSession = (serviceToken: string | null | undefined): void => {
   if (serviceToken) {
      sessionStorage.setItem("serviceToken", serviceToken);
      apiServices.setAuthHeader(`Bearer ${serviceToken}`);
   } else {
      sessionStorage.removeItem("serviceToken");
      apiServices.deleteAuthHeader();
   }
};

const JWTContext = createContext({
   ...initialState,
   login: (email: string, password: string) => Promise.resolve(),
   logout: () => {},
});

export const JWTProvider: React.FC = ({ children }) => {
   const [state, dispatch] = useReducer(accountReducer, initialState);
   const session = useContext(SessionContext);

   const login = async (email: string, password: string) => {
      const response = await apiServices.post("/users/login", { email, password });
      const { token, user }: { token: string | null | undefined; user: UserInterface } =
         response.data;
      setSession(token);
      session.dispatch({ type: SessionStateActionType.SetDomain, domain: user.domains[0] });
      dispatch({
         type: AccountActionType.LOGIN,
         payload: {
            user,
         },
      });
   };

   const logout = () => {
      apiServices.post("/users/me/logout").then(() => {
         setSession(null);
         dispatch({ type: AccountActionType.LOGOUT });
      });
   };

   useEffect(() => {
      const init = async () => {
         try {
            const serviceToken = window.sessionStorage.getItem("serviceToken");
            if (serviceToken && verifyToken(serviceToken)) {
               setSession(serviceToken);
               const response = await apiServices.get("/users/me");
               const { user } = response.data;
               session.dispatch({
                  type: SessionStateActionType.SetDomain,
                  domain: user.domains[0],
               });
               dispatch({
                  type: AccountActionType.ACCOUNT_INITIALISE,
                  payload: {
                     isLoggedIn: true,
                     user,
                  },
               });
            } else {
               dispatch({
                  type: AccountActionType.ACCOUNT_INITIALISE,
                  payload: {
                     isLoggedIn: false,
                     user: null,
                  },
               });
            }
         } catch (err) {
            console.error(err);
            dispatch({
               type: AccountActionType.ACCOUNT_INITIALISE,
               payload: {
                  isLoggedIn: false,
                  user: null,
               },
            });
         }
      };

      init();
      // eslint-disable-next-line
   }, []);

   if (!state.isInitialised) {
      return <Loader />;
   }

   return <JWTContext.Provider value={{ ...state, login, logout }}>{children}</JWTContext.Provider>;
};

export default JWTContext;
